.list-decimal { 
  @apply ml-5;

  li::marker { 
    @apply font-bold;
  }
}

li { 
  @apply mb-2;
}

ul {
  @apply mt-2;
}
