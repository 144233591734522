.custom-menubar {
  background-color: #161616;
  border: none;
  box-shadow: none;
}

.custom-menubar .p-menubar {
  border: none;
  box-shadow: none;
}

.custom-menubar .p-menuitem-link {
  color: #ffffff !important;
  text-decoration: none !important;
  background-color: transparent !important;
  position: relative;
}

.custom-menubar .p-menu {
  background-color: #000000 !important;
  border: none !important;
}

.custom-menubar .p-menu .p-menuitem-link {
  color: #ffffff !important;
}

.p-menuitem-content {
  background: transparent !important;
}

.p-menuitem-text:hover {
  color: #806a3d !important;
}

.p-submenu-list{
  background: black !important;
}

.p-submenu-list .p-menuitem-text{
  color: white;
}

.p-submenu-list .p-menuitem-text:hover{
  text-decoration: none !important;
}

.custom-menubar .p-menuitem-link .p-menuitem-text{
  color: white;
}

.p-submenu-list .p-menuitem-link:hover span {
  color: #806a3d;
}

a:hover {
  color: #806a3d;
}
