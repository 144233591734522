@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

.transition-transform {
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

html {
  scroll-behavior: smooth;
}

.user-avatar img {
  height: 100%;
}

.admin-tabs, .equipments-tabs {
  .p-tabview-nav {
    border-bottom: 2px solid lightgrey;
    margin: 0;
  }

  li.p-highlight {
    @apply text-moderate-orange;
    padding: 2px 2px 0 2px;
    border: 1px solid;
    border-bottom: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-bottom: 0;
  }  
}
