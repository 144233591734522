.custom-carousel .p-carousel-prev-icon,
.custom-carousel .p-carousel-next-icon {
  width: 2rem;
  height: 2rem;
  color: white;
}

.custom-rating .p-icon .p-rating-icon {
  @apply text-moderate-orange;
}

.custom-rating .p-rating-icon {
  @apply text-moderate-orange;
}
