.custom-header {
  background-color: #161616; 
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  
}

.p-dialog-content.custom-header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}

.p-dialog {
  box-shadow: none !important;
}

.p-radiobutton.p-highlight .p-radiobutton-box {
  border-color: #806a3d !important;
  background: #806a3d !important;
}